import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const Error404 = lazy(() => import("./views/misc/error/404"));
const Payment = lazy(() => import("./views/pages/Payment"));

const AppRouter = () => {
  return (
    // Set the directory path if you are deploying in sub-folder
    <Router>
      <Switch>
        <Route exact path="/" component={Payment} />
        <Route component={Error404} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
